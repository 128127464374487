const dayjs = useDayjs()

export const useStats = async () =>{

    const store = useStatsStore()
    const errorStore = useErrorStore()

    const getStats = async () => {
        try {
            const {data:stats,message} = await useAxiosGet('analytics/dashboard')
            store.setStats(stats)
            return stats
        } catch (error) {
            console.log("Dashboard Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const getChart = async (from=null,to=null) => {
        try {
            let fromDate = dayjs().subtract(7,'days').unix()
            let toDate = dayjs().add(1, 'day').unix()
            const {data:chart,message} = await useAxiosGet(`analytics/activity-chart?from=${fromDate}&to=${toDate}`)
            let chart_labels = chart.map(c=>c.Date)
            let chart_duration = chart.map(c=>c.Duration)
            return {chart_labels:chart_labels,chart_duration:chart_duration}
        } catch (error) {
            console.log("Dashboard Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    return { getStats,getChart }
}